import FooterDetail from "./FooterDetail/FooterDetail";
import Gallery from "./Gallery/Gallery";



const Footer = () => {
    return (
        <>
            <Gallery />
            <FooterDetail />
        </>
    );
}

export default Footer;