import React from 'react'
import AddSP from '../../../components/Admin/SanPham/AddSP'

const AddSanPham = () => {
    return (
        <div>
            <AddSP />
        </div>
    )
}

export default AddSanPham