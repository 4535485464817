import React from 'react'
import NavBar from '../../../components/Admin/NavBar/NavBarAdmin'
import ListDanhMuc from '../../../components/Admin/DanhMuc/ListDanhMuc'

const AllDanhMuc = () => {
    return (
        <div>
            <ListDanhMuc />
        </div>
    )
}

export default AllDanhMuc