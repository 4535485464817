import React from 'react'
import EditDM from '../../../components/Admin/DanhMuc/EditDM'

const EditDanhMuc = () => {
    return (
        <div>
            <EditDM />
        </div>
    )
}

export default EditDanhMuc