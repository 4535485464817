import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer
  // FLUSH,
  // REHYDRATE,
  // PAUSE,
  // PERSIST,
  // PURGE
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "../features/index";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "cart"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});
export const persistor = persistStore(store);

