import React from 'react'
import AddDM from '../../../components/Admin/DanhMuc/AddDM'

const AddDanhMuc = () => {
    return (
        <div>
            <AddDM />
        </div>
    )
}

export default AddDanhMuc