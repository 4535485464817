import React from 'react'
import Products from '../../components/Product/Products'
import Slider from '../../components/Slider/Slider'





const AllProduct = () => {
    return (
        <>
            <Slider />
            <Products />
        </>
    )
}

export default AllProduct