import React from 'react'
import ListSP from '../../../components/Admin/SanPham/ListSP'

const AllSanPham = () => {
    return (
        <div>
            <ListSP />
        </div>
    )
}

export default AllSanPham